body {
  background-color: #ebebeb;
  font-family: 'Barlow', sans-serif;
  padding: 0;
  margin: 0;
}

.home-inputgroup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 85%;
  max-width: 500px;
  align-items: center;
  box-shadow: 0px 0px 15px #f9f9f98f;
  color: #006539;
}


#orderDetail td,
#orderDetail th {
  text-align: left;
  padding: 10px 5px;
  border-bottom: 1px solid #16b313;
}

.navactivee {
  background: #2e7d32 !important;
  border-radius: 49px !important;
  color: #fff !important;
}

.bannernn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: linear-gradient(333deg, #bfdb7c, #00a556);
  border-radius: 0px 0px 40px 40px;
  box-shadow: 0px 0px 26px #2060148c;
  position: relative;
  overflow: hidden;
}

.operatorDetails {
  display: flex;
  color: #fff;
  justify-content: space-between;
  padding: 10px 30px;
  width: 85%;
  align-items: baseline;
  font-size: 20px;
}

p.operatorDetails-head {
  font-size: 16px;
  margin: 0;
}

p.operatorDetails-body {
  font-size: 18px;
  margin: 0;
  border-top: 1px solid #fff;
  margin-top: 5px;
  padding-top: 5px;
  padding-left: 2px;
  display: flex;
  font-weight: 700;
  align-items: center;
  gap: 10px;
}

.PendingOrder {
  text-align: end;
}

.home-inputgroup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 85%;
  max-width: 500px;
  align-items: center;
  box-shadow: 0px 0px 15px #a2fda5ad;
  color: #006539;
}

ul.orderList {
  width: 90%;
  padding: 0;
  list-style: none;
}

li.orderlistitem {
  padding: 10px 15px;
  background: #fff;
  margin-bottom: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: 0px 2px 7px #999999b8;
}

.settingHeader {
  width: 85%;
  padding: 0 20px;
  background: #fff;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 2px 10px #a1a0a0ba;
}

.settingHeader h2 {
  text-transform: capitalize;
  color: #2e7d32;
  margin-bottom: 0px;
}

.settingHeader p {
  font-size: 16px;
  font-weight: 500;
  color: #090e09;
}

ul.settinglist li {
  margin-bottom: 10px;
  padding: 15px 15px;
  background: #2e7d32;
  color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 10px #91b793;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

ul.settinglist {
  width: 93%;
  padding: 0;
  list-style: none;
}

.spinner {
  animation: spin 2s linear infinite;
  height: 25px;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}